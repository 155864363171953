<template>
    <div class="admin_parent">
        <div class="clearfix admin_nav">
            <div class="navig_logo fl">
                <img src="./../../assets/img/logo.png" alt="">
            </div>
            <div class="fr navig_div">
                <div class="navig_title">
                    <h3 class="fl">{{this.$store.state.schName}}({{this.$store.state.depName}}) - 院系管理端 </h3>
                    <div class="fl navig_divs">
                        <span>项目填报开始截止时间：{{this.$store.state.startTime}} —— {{this.$store.state.endTime}}</span>
                    </div>
                    <span class="fr" @click="exit()">
                        <el-button type="info" size="small" plain>退出</el-button>
                    </span>
                </div>
            </div>
        </div>
        <div class="clearfix admin_all">
            <div class="fl admin_left">
                <el-menu
                    :default-active="activePath"
                    class="el-menu-vertical-demo"
                    :unique-opened='true'
                    background-color="#545c64"
                    text-color="#fff"
                    active-text-color="#ffd04b">
                    <router-link to="Teacherment">
                        <el-menu-item index="Teacherment">
                            <i class="iconfont icon-yonghuguanli" style="margin-right:10px;font-size:18px"></i>
                            <span slot="title">教师管理</span>
                        </el-menu-item>
                    </router-link>
                    <el-submenu index="2">
                        <template slot="title">
                            <i class="iconfont icon-xiangmuguanli-" style="margin-right:10px;font-size:18px"></i>
                            <span>项目管理</span>
                        </template>
                        <!-- <router-link to="Seconbatch">
                            <el-menu-item index="Seconbatch">项目批次</el-menu-item>
                        </router-link> -->
                        <router-link to="Seconlistofitems">
                            <el-menu-item index="Seconlistofitems">项目列表</el-menu-item>
                        </router-link>
                        <!-- <router-link to="Seconectcation">
                            <el-menu-item index="Seconectcation">项目申报</el-menu-item>
                        </router-link> -->
                        <router-link to="Secontcheck">
                            <el-menu-item index="Secontcheck">中期检查</el-menu-item>
                        </router-link>
                    </el-submenu>
                    <router-link to="Seconingfunds">
                        <el-menu-item index="Seconingfunds">
                            <i class="iconfont icon-zijinguanli" style="margin-right:10px;font-size:18px"></i>
                            <span slot="title">经费审核</span>
                        </el-menu-item>
                    </router-link>
                    <router-link to="SeconPassword">
                        <el-menu-item index="SeconPassword">
                            <i class="iconfont icon-zhanghaoguanli" style="margin-right:10px;font-size:18px"></i>
                            <span slot="title">修改密码</span>
                        </el-menu-item>
                    </router-link>
                    <!-- <el-submenu index="3">
                        <template slot="title">
                            <i class="iconfont icon-shujutongji" style="margin-right:10px;font-size:18px"></i>
                            <span>数据统计</span>
                        </template>
                        <router-link to="Seconstatistical">
                            <el-menu-item index="Seconstatistical">专利统计</el-menu-item>
                        </router-link>
                        <router-link to="Secontatistics">
                            <el-menu-item index="Secontatistics">项目统计</el-menu-item>
                        </router-link>
                        <router-link to="Seconmoneytics">
                            <el-menu-item index="Seconmoneytics">资金统计</el-menu-item>
                        </router-link>
                    </el-submenu> -->
                    <el-menu-item index="4">
                        <i class="iconfont icon-xitongxiaoxi" style="margin-right:10px;font-size:18px"></i>
                        <span slot="title">系统消息</span>
                    </el-menu-item>
                </el-menu>
            </div>
            <div class="fr admin_right">
                <div class="bread_container">
                    <el-breadcrumb class="breadcrumb" separator="/">
                        <el-breadcrumb-item 
                            v-for='(name,index) in $route.meta'
                            :key='index'
                            >
                            {{name}}
                        </el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
                <div class="bread_view">
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            activePath:'',
        }
    },
    methods: {
        exit(){
            this.$router.push({name:'login'})
        },
        getPath(){
            let currentPath = this.$route
            this.activePath = currentPath.name
            if(this.$route.name=="Secondetails"){
                this.activePath = 'Seconlistofitems'
            }else{
                this.activePath = currentPath.name
            }
        },
    },
    watch:{
        $route(){
            this.getPath()
        }
    },
    mounted() {
        this.getPath()
    },
}
</script>
<style scoped>
@import './../../assets/css//admin/admin.css';
</style>
<style>
.admin_left .el-menu{
    border-right: none;
}
.admin_left .el-submenu__title i{
    color: #fff;
}
.admin_left .el-menu-item i{
    color: #fff;
}
.admin_left .is-active{
    background: rgb(67, 74, 80)!important;
}
</style>